import Vue from 'vue'
import App from './App.vue'
import router from './router'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import * as VueGoogleMaps from 'vue2-google-maps'
import locale from 'element-ui/lib/locale/lang/zh-TW'; // 引入繁体中文
Vue.config.productionTip = false
Vue.use(ElementUI, { locale });
new Vue({
  router,
  render: h => h(App)
}).$mount('#app')


Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyDvpz-TIAtBUFGJhL3nF7FELUGam8oysXA',
    libraries: 'places', 
  },
  installComponents: true
})
